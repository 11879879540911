import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { colors, margins, fontSizes, breakpoint, alpha } from '../cms/style'
import useWindowSize from '../hooks/useWindowSize.hook'
import CMSData from '../cms/data/main-page/data'

import Logo from '../components/Logo.component'
import { PathNull, PathReklama, PathDoradztwo, PathBiznes } from '../assets/PathIco'


const Svgs = ({ activeSection, dataStateConstants }) => (
  <>
    <PathReklama isActive={activeSection === dataStateConstants.reklama} />
    <PathDoradztwo isActive={activeSection === dataStateConstants.doradztwo} />
    <PathBiznes isActive={activeSection === dataStateConstants.biznes} />
    <PathNull isActive={activeSection === ''} />
  </>
)

// <! -------  --------  -------  --------  !>

const BrandItem = ({ name, color, activeSection, onMouseEnter, onMouseLeave }) => (
  <div
    css={styles.childDiv}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <h2
      style={
        activeSection === name.toLowerCase()
          ? { color, width: '100%', left: 0, textAlign: 'center' }
          : { width: '100%', left: 0, textAlign: 'center' }
      }
      css={styles.childDiv.rdbName}
    >
      {name}
    </h2>
  </div>
)

// <! -------  --------  -------  --------  !>

export default function S1({
  handleChangeActiveSection,
  activeSection,
  dataStateConstants }) {
  const brandsData = [
    {
      id: dataStateConstants.reklama,
      name: CMSData.reklama.headerDiv.header,
      color: colors.brands.reklama
    },
    {
      id: dataStateConstants.doradztwo,
      name: CMSData.doradztwo.headerDiv.header,
      color: colors.brands.drony
    },
    {
      id: dataStateConstants.biznes,
      name: CMSData.biznes.headerDiv.header,
      color: colors.brands.biznes
    }
  ]
  const { width, height } = useWindowSize()

  const backgroundImg = useStaticQuery(graphql`
  query {
    backgroundImg: file(relativePath: { eq: "background-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`)

  const [transformValues, setTransformValues] = useState({ x: -50, y: -50 })
  const _onMouseMove = (e) => {
    const xHalf = width / 2
    const xCof = e.nativeEvent.clientX / xHalf
    const yHalf = height / 2
    const yCof = e.nativeEvent.clientY / yHalf
    const cof = 3

    if (xCof <= 1) {
      if (yCof <= 1) {
        setTransformValues({ x: -50 + xCof * cof, y: -50 + yCof * cof })
      } else {
        setTransformValues({ x: -50 + xCof * cof, y: -50 - yCof * cof })
      }
    } else {
      if (yCof <= 1) {
        setTransformValues({ x: -50 - xCof * cof, y: -50 + yCof * cof })
      } else {
        setTransformValues({ x: -50 - xCof * cof, y: -50 - yCof * cof })
      }
    }
  }

  return (
    <section css={styles.section} onMouseMove={_onMouseMove}>
      <div css={styles.backgroundImg}>
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: colors.brands[activeSection]
              ? colors.brands[activeSection].concat(alpha[10])
              : colors.brands['reklama'].concat(alpha[10]),
            transition: 'background-color .5s ease-out'
            // zIndex: 1 
          }}
        />
        <Img
          imgStyle={{ zIndex: -1, opacity: '.35' }}
          style={{ height: '100vh' }}
          fluid={backgroundImg.backgroundImg.childImageSharp.fluid}
        />
      </div>
      <div css={styles.logo}>
        <Logo />
      </div>
      <div css={styles.logoSign} style={{ transform: `translate(${transformValues.x + '%'}, ${transformValues.y + '%'})` }}>
        <Svgs activeSection={activeSection} dataStateConstants={dataStateConstants} />
      </div>
      {/* {spheres.map((el, index) =>
        <SphereAbsolute
          key={index}
          id={index}
          color={colors.brands[activeSection]}
          size={el.size}
          position={el.position}
        />)} */}
      {brandsData.map(el =>
        <BrandItem
          key={el.id}
          name={el.name}
          color={el.color}
          onMouseEnter={() => handleChangeActiveSection(el.id)}
          // onMouseLeave={() => handleChangeActiveSection('')}
          activeSection={activeSection}
        />)}
    </section>
  )
}

const styles = {
  section: {
    display: 'flex',
    position: 'relative',
    height: '100vh',
    width: '100vw',
    zIndex: 50,

    [breakpoint.xs]: {
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
  },

  logo: {
    // width: 80,
    height: 120,
    position: 'absolute',
    top: 50,
    left: margins.sectionsX.xl,
    zIndex: 9999,

    svg: {
      height: '100%'
    },

    [breakpoint.lg]: {
      left: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      left: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      left: margins.sectionsX.sm,
      top: 30,
      height: 100
    },
    [breakpoint.xs]: {
      left: margins.sectionsX.xs,
      top: 30,
      height: 70
    },
  },

  logoSign: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '260px',
    height: '400px',
    transform: 'translate(-50%, -50%)',
    transition: 'transform .3s linear',
    textAlign: 'center',

    svg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      transition: 'opacity .2s ease-out',
      opacity: 0
    },

    [breakpoint.md]: { width: 150, height: 157 },
    [breakpoint.xs]: { top: '42%', width: 125, height: 130 }
  },

  childDiv: {
    width: '100%',
    position: 'relative',
    transition: '.2s',
    userSelect: 'none',

    rdbName: {
      position: 'absolute',
      bottom: '5vh',
      left: margins.sectionsX.lg / 2,
      fontSize: fontSizes.fz1.lg,
      letterSpacing: '-' + fontSizes.fz1.lgxl * 0.08 + 'px',
      transition: '.2s',
      color: colors.headers.brandTop,

      [breakpoint.lg]: {
        left: margins.sectionsX.md / 2,
        letterSpacing: '-' + fontSizes.fz1.md * 0.08 + 'px',
        fontSize: fontSizes.fz1.md,
      },
      [breakpoint.md]: {
        left: margins.sectionsX.sm / 2,
        letterSpacing: '-' + fontSizes.fz1.sm * 0.08 + 'px',
        fontSize: fontSizes.fz1.sm,
      },
      [breakpoint.sm]: {
        left: margins.sectionsX.xs / 2,
        letterSpacing: '-' + fontSizes.fz1.xs * 0.08 + 'px',
        fontSize: fontSizes.fz1.xs,
      },
    },

    [breakpoint.xs]: {

      h2: {
        position: 'static',
        textAlign: 'left!important',
        paddingLeft: margins.sectionsX.xs,
      },
      '&:last-of-type': {
        h2: {
          marginBottom: margins.topBtmGradientHeight.xs / 2
        }
      },
    }
  },

  backgroundImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: '-2',
    backgroundColor: '#fff'
  }
}