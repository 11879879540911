import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import S1 from '../sections/s1.section'
import S2 from '../sections/s2.section'
import S3 from '../sections/s3.section'
import S4footer from '../sections/s4.footer.section'
import Menu from '../components/Menu.component'

import CMSData from '../cms/data/main-page/data'
import dataStateConstants from '../constants/dataState.constants'

const IndexPageTemplate = ({ initial }) => {
  const { reklama, doradztwo, biznes } = dataStateConstants
  const [activeSection, setActiveSection] = React.useState(initial)

  const handleChangeActiveSection = i => setActiveSection(i)

  return (
    <>
      <Menu
        activeSection={activeSection}
        dataStateConstants={dataStateConstants}
        handleChangeActiveSection={handleChangeActiveSection}
      />
      <S1
        handleChangeActiveSection={handleChangeActiveSection}
        activeSection={activeSection}
        dataStateConstants={dataStateConstants}
      />
      <S2 data={CMSData[activeSection]} activeSection={activeSection} />
      <S3 data={CMSData[activeSection]} activeSection={activeSection} />
      <S4footer />
    </>
  )
}

export default IndexPageTemplate
