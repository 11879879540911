import React from 'react'
import { Link } from 'gatsby'
import { colors, breakpoint } from '../cms/style'
import './Menu.styles.css'
import dataStateConstants from '../constants/dataState.constants'


const Menu = ({ activeSection, handleChangeActiveSection }) => {
  const { reklama, doradztwo, biznes } = dataStateConstants
  const menuData = [
    { url: '/reklama', id: reklama, name: 'Reklama' },
    { url: '/doradztwo', id: doradztwo, name: 'Doradztwo' },
    { url: '/biznes', id: biznes, name: 'Biznes' },
  ]

  return (
    <nav className="menu" css={styles.mainDiv}>
      <div>
        <p css={styles.menuItem}>
          <Link to="/">RDB Group</Link>
          <span />
        </p>
      </div>
      <div className="menu-flex">
        {menuData.map((el, index) =>
          <p
            key={index}
            // css={styles.menuItem}
            css={[
              styles.menuItem,
              activeSection === el.id ? styles.menuItemActive : ''
            ]}
          >
            <Link
              to={el.url}
              onClick={(e) => {
                e.preventDefault()
                handleChangeActiveSection(el.id)
              }}
            >{el.name}</Link>
          <span />
          </p>
        )}
      </div>
    </nav>
  )
}

const styles = {
  mainDiv: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80vh',
    height: 48,
    right: 0,
    top: 32,
    transform: 'rotate(90deg) translate(50%, -35vh)',
    textTransform: 'uppercase',
    fontWeight: 700,
    zIndex: 50,

    [breakpoint.sm]: {
      height: 36,
      transform: 'rotate(90deg) translate(50%, -37vh)',
    },

    ">div": {
      margin: '0 32px',

      [breakpoint.xs]: {
        margin: '0 24px 0 12px'
      }
    }
  },

  menuItem: {
    position: 'relative',
    lineHeight: '1!important',
    width: 'max-content',

    a: {
      display: 'inline-block',
      color: colors.links.normal,
      textDecoration: 'none',
      margin: '0 24px',
      transition: 'color .15s ease-out',

      [breakpoint.sm]: {
        fontSize: 14,
        margin: '0 20px'
      },
    },

    span: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '27px', // 2rem - spanHeight/2
      transform: 'translateX(-50%)',
      background: '#ffffff',
      border: `2px solid ${colors.links.normal}`,
      borderRadius: '50%',
      height: 12,
      width: 12,
      margin: 0,
      zIndex: 10,
      transition: 'border .15s ease-out, background .15s ease-out',

      [breakpoint.sm]: {
        top: 21
      },
    },



    //   "&:hover": {
    //     a: {
    //       color: colors.links.active
    //     },

    //     span: {
    //       background: colors.links.activeCircle,
    //       border: `2px solid ${colors.links.activeCircle}`,
    //     }
    // }
  },

  menuItemActive: {
    a: {
      color: colors.links.active
    },

    span: {
      background: colors.links.activeCircle,
      border: `2px solid ${colors.links.activeCircle}`,
    }
  }
}

export default Menu