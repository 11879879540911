import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { colors, margins, fontSizes, breakpoint } from "../cms/style"
import stringEnter from "../utils/stringEnter.util"
import dataState from "../constants/dataState.constants"

export default function S2({ data, activeSection }) {
  const { header, subheader, text } = data.headerDiv

  const imgQuery = useStaticQuery(graphql`
    query {

      # Reklama starts
      branding: file(
        relativePath: { eq: "01-info-icons/reklama/BRANDING.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      businessModeling: file(
        relativePath: { eq: "01-info-icons/reklama/BUSINESS-MODELING.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designStrategy: file(
        relativePath: { eq: "01-info-icons/reklama/DESIGN-STRATEGY.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecommerce: file(
        relativePath: { eq: "01-info-icons/reklama/E-COMMERCE.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marketingOutsourcing: file(
        relativePath: { eq: "01-info-icons/reklama/MARKETING-OUTSOURCING.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      seoSem: file(relativePath: { eq: "01-info-icons/reklama/SEO-SEM.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      socialMedia: file(
        relativePath: { eq: "01-info-icons/reklama/SOCIAL-MEDIA.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webAdv: file(
        relativePath: { eq: "01-info-icons/reklama/WEB-ADVERTISING.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webDev: file(
        relativePath: { eq: "01-info-icons/reklama/WEB-DEVELOPMENT.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imgDronyFluids = []

  const imgReklamaFluids = [
    imgQuery.marketingOutsourcing.childImageSharp.fluid,
    imgQuery.designStrategy.childImageSharp.fluid,
    imgQuery.branding.childImageSharp.fluid,
    imgQuery.businessModeling.childImageSharp.fluid,
    imgQuery.socialMedia.childImageSharp.fluid,
    imgQuery.seoSem.childImageSharp.fluid,
    imgQuery.webAdv.childImageSharp.fluid,
    imgQuery.webDev.childImageSharp.fluid,
    imgQuery.ecommerce.childImageSharp.fluid,
  ]

  const imgBiznesFluids = []

  function whichImages(index) {
    switch (activeSection) {
      case dataState.reklama:
        return imgReklamaFluids[index]

      case dataState.doradztwo:
        return imgDronyFluids[index]

      case dataState.biznes:
        return imgBiznesFluids[index]
    }
  }

  return (
    <section css={styles.mainSection}>
      <div css={styles.info}>
        <h3>{header}</h3>
        <h5>{subheader}</h5>
        {stringEnter(text)}
      </div>

      <div css={styles.itemsParent}>
        {data.services.map((el, index) => (
          <div key={index} css={styles.itemsChild}>
            {whichImages(index) ? ( <div css={{ width: 60, height: 60, marginRight: 20 }}>
              <Img fluid={whichImages(index)} />
            </div>) : null}
            <div css={{ width: "calc(100% - 80px)" }}>
              <h4>{el.header}</h4>
              <p>{el.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
      paddingRight: 48,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
      paddingRight: 48,
    },
  },

  info: {
    width: "50%",

    h3: {
      color: colors.headers.lightBlack,
      fontSize: fontSizes.fz2.xl,
      letterSpacing: "-3px",
    },

    h5: {
      color: colors.headers.grey,
      fontSize: fontSizes.fz4.xl,
      marginBottom: 36,
      letterSpacing: "-0.6px",
    },

    p: {
      marginBottom: ".5rem",
    },

    [breakpoint.lg]: {
      h3: {
        fontSize: fontSizes.fz2.lg,
      },

      h5: {
        fontSize: fontSizes.fz4.lg,
      },

      p: {
        fontSize: fontSizes.fz4.lg,
      },
    },
    [breakpoint.md]: {
      width: "70%",

      h3: {
        fontSize: fontSizes.fz2.md,
      },

      h5: {
        fontSize: fontSizes.fz4.md,
      },

      p: {
        fontSize: fontSizes.fz4.md,
      },
    },
    [breakpoint.sm]: {
      width: "100%",

      h3: {
        fontSize: fontSizes.fz2.sm,
      },

      h5: {
        fontSize: fontSizes.fz4.sm,
        marginBottom: 18,
      },

      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
    [breakpoint.xs]: {
      h3: {
        fontSize: fontSizes.fz2.xs,
      },

      h5: {
        fontSize: fontSizes.fz4.xs,
      },

      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
  },

  itemsParent: {
    display: "flex",
    flexWrap: "wrap",
    margin: "64px 0 0",
  },

  itemsChild: {
    display: "flex",
    width: "33.333%",
    padding: "32px 7% 32px 0",

    h4: {
      fontSize: fontSizes.fz4.xl,
      color: colors.headers.black,
      marginBottom: 8,
    },

    p: { fontSize: fontSizes.fz5.xl },

    [breakpoint.lg]: {
      p: { fontSize: fontSizes.fz5.lg },
      h4: { fontSize: fontSizes.fz4.lg },
    },
    [breakpoint.md]: {
      width: "50%",

      p: { fontSize: fontSizes.fz5.md },
      h4: { fontSize: fontSizes.fz4.md },
    },
    [breakpoint.sm]: {
      width: "100%",

      p: { fontSize: fontSizes.fz5.sm },
      h4: { fontSize: fontSizes.fz4.sm },
    },
    [breakpoint.xs]: {
      p: { fontSize: fontSizes.fz5.xs },
      h4: { fontSize: fontSizes.fz4.xs },
    },
  },
}
