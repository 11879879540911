export const reklama = {
  headerDiv: {
    header: "Reklama",
    subheader: "Marketing Outsourcing",
    text: `Strategia, marketing i reklama to bardzo ważne elementy każdego biznesu. W strategii naszej grupy na początku postawiliśmy na rozwój kompetencji marketingowych. To przewaga, która pozwala przyspieszyć proces wzrostu biznesu. Dzielimy się doświadczeniem i oferujemy nasze usługi firmom chcącym szybko się rozwijać.`,
  },
  services: [
    {
      header: "MARKETING OUTSOURCING",
      text: `Agencja reklamowa bierze odpowiedzialność za wszystkie działania marketingowe. Tworzy strategię oraz przygotowuje komunikację i promocję marki.Odpowiada za realizację założonych celów i wdraża najnowsze rozwiązania do marketingu organizacji.`,
    },
    {
      header: "DESIGN STRATEGY",
      text: `Projektujemy zorientowane na przyszłość zasady w celu podniesienia innowacyjności i konkurencyjności organizacji.                    `,
    },
    {
      header: "BRANDING",
      text: `
      Pod tą nazwą kryje się szereg działań związanych z budowaniem świadomości marki. Polega ono na kreowaniu i utrzymaniu pozytywnego wizerunku w umysłach konsumentów poprzez elementy identyfikacji wizualnej, kolorów, logotypu i innych elementów charakterystycznych marki.`,
    },
    {
      header: "BUSINESS MODELING",
      text: `Dzięki przygotowaniu modelu biznesowego odkryjesz długookresową metodę na powiększenie i wykorzystanie zasobów w celu przedstawienia klientom oferty przewyższającej ofertę konkurencji, przy jednoczesnym zapewnieniu organizacji dochodowości.  `,
    },
    {
      header: "SOCIAL MEDIA",
      text: `To niezbędny w reklamie każdej firmy kanał komunikacji. Platformy społecznościwe wykorzystują elementy wideo, grafiki, copywriting. Kluczem do sukcesu jest przygotowanie strategii, która sprawi, że obecność firmy w social media będzie spójna i sensowna. `,
    },
    {
      header: "SEO & SEM",
      text: `
      Organiczne pozycjonowanie marki skoncentrowane na wartościowym kontencie oraz optymalizacji strony internetowej. To niezbędny element długofalowego budowania rozpoznawalności marki.`,
    },
    {
      header: "WEB ADVERTISING",
      text: `
      Reklama online, znana również jako marketing online, reklama internetowa, reklama cyfrowa. To forma marketingu, która wykorzystuje Internet do dostarczania wiadomości marketingowych konsumentom.`,
    },
    {
      header: "WEB DEVELOPMENT",
      text: `
      Tworzenie stron internetowych i elementów niezbędnych do zaistnienia i funkcjonowania marki w sieci.`,
    },
    {
      header: "E-COMMERCE",
      text: `
      Platformy obsługujące zakupy online. Umożliwiają wyeksponowanie produktu oraz przeprowadzenie transakcji zakupowej. `,
    },
  ],
  brands: [
    {
      header: "ReklamaDlaBiznesu",
      subheader: "Agencja Marketingowa",
      text: `
      Agencja Marketingowa odpowiadająca za marki szybko rozwijających się podmiotów skoncentrowanych na swoim głównym obszarze działalności. Główną usługą Reklamy Dla Biznesu jest Marketing Outsourcing.`,
      internal: "/reklama-dla-biznesu",
      external: "https://reklamadlabiznesu.pl",
    },
    {
      header: "RDB Socials",
      subheader: "Pakiety Social Media",
      text: `
      Media społecznościowe to podstawowy punkt styku marki z klientem, dlatego biorąc pod uwagę potrzeby naszych klientów stworzyliśmy gotowe pakiety umożliwiające obecność marki w mediach społecznościowcy i wykorzystanie najlepszych praktyk do zwięksaznia zaangażowania na konkretnych kanałach.`,
      internal: "/rdb-socials",
    },
    {
      header: "RDB Media",
      subheader: "Studio Produkcji Filmowej",
      text: `
      To marka odpowiadająca w naszej grupie za produkcję video. W ofercie RDB Media znajdują się filmy promocyjne, korporacyjne, szkoleniowe, wywiady i relacje.`,
      external: "https://rdbmedia.pl",
    },
    {
      header: "Ruby Studio",
      subheader: "Studio Oklejania Reklamowego",
      text: `
      Studio Wrapingowe zajmujące się oklejaniem reklamowym różnego rodzaju powierzchni. Okna, reklamy, samochody, jachty, samoloty oraz inne przedmioty, Ruby Studio to marka uzupełniająca ofertę RDB Group dedykowana głównie klientom agencyjnym.`,
      internal: "/ruby-studio",
    },
  ],
}

export const doradztwo = {
  headerDiv: {
    header: "Doradztwo",
    subheader: "",
    text: `W dzisiejszym dynamicznym środowisku biznesowym, kluczem do sukcesu jest nie tylko skuteczna sprzedaż, ale również precyzyjne zarządzanie procesami i optymalizacja działań marketingowych. Oferujemy kompleksowe doradztwo biznesowe, które obejmuje szeroki zakres usług, mających na celu wzmocnienie pozycji Twojej firmy na rynku.`,
  },
  services: [
    {
      header: "Sprzedaż i CRM",
      text: `Nasze doświadczenie w obszarze sprzedaży i zarządzania relacjami z klientami (CRM) pozwala na skuteczne budowanie długotrwałych relacji biznesowych. Pomagamy w tworzeniu i wdrażaniu strategii sprzedaży, która zwiększy Twoje wyniki, oraz zapewni optymalizację procesów sprzedażowych dzięki nowoczesnym narzędziom CRM.`,
    },
    {
      header: "Mapowanie Procesów Biznesowych",
      text: `Zrozumienie i optymalizacja kluczowych procesów w Twojej firmie jest fundamentem do osiągnięcia wyższej efektywności operacyjnej. Mapujemy i analizujemy procesy biznesowe, aby zidentyfikować obszary do poprawy i wprowadzić zmiany, które realnie wpłyną na poprawę wyników Twojej organizacji.`,
    },
    {
      header: "Projektowanie Ścieżki Sprzedaży",
      text: `Efektywna ścieżka sprzedaży to klucz do sukcesu. Projektujemy ścieżki sprzedaży dostosowane do specyfiki Twojego biznesu, co pozwala na maksymalizację konwersji oraz budowanie trwałych relacji z klientami. Nasze podejście uwzględnia najnowsze trendy i technologie, co umożliwia osiąganie wyznaczonych celów biznesowych.`,
    },
    {
      header: "Narzędziowe Przygotowanie Działu Sprzedaży",
      text: `Nowoczesny dział sprzedaży wymaga odpowiednich narzędzi i technologii, aby działać efektywnie. Oferujemy kompleksowe wsparcie w doborze i wdrożeniu narzędzi, które usprawnią pracę Twojego zespołu sprzedażowego, zapewniając tym samym lepszą organizację, efektywność oraz mierzalność wyników.`,
    },
    {
      header: "Automatyzacja Sprzedaży",
      text: `Automatyzacja procesów sprzedażowych to przyszłość każdego biznesu. Wdrażamy rozwiązania, które pozwalają na automatyzację kluczowych elementów sprzedaży, co przekłada się na oszczędność czasu, lepszą obsługę klienta oraz wzrost wyników sprzedażowych.`,
    }
  ],
  brands: [],
}

export const biznes = {
  headerDiv: {
    header: "Biznes",
    subheader: "Inwestycje RDB Group",
    text: [
      `
    To obszar, w którym znalezliśmy się poprzez rozwój poszczególnych sektorów naszej działalności. Marki, które kiedyś funkcjonowały w ramach jednego brandu, dzisiaj są samodzielnie funkcjonującymi podmiotami posiadającymi odrębną osobowość prawną oraz lidera. To własnie w taki sposób powstała i rozwija się nasza grupa.
     `,
    ],
  },
  services: [
    {
      header: "Doradztwo",
      text: `
      Opracowanie modelu biznesowego w oparciu o szczegółową analizę rynku pozwala wybrać odpowiednie kierunki działań. Nasze doświadczenie w tym zakresie pozwala skutecznie zarządzać budżetem początkowym.`,
    },
    {
      header: "Dofinansowania",
      text: `
      Jako grupa podmiotów szukających najskuteczniejszych rozwiązań dla rozwoju biznesu wielokrotnie korzystaliśmy ze środków oferowanych przez PARP, KFS oraz innych lokalnych operatorów. Dzięki nam możesz dowiedzieć się jak pozyskać środki i jakie projekty będą najlepsze dla Twojego biznesu.                    `,
    },
    {
      header: "Współpraca",
      text: `
      Rozwijanie długoterminowej współpracy z partnerami i umożliwianie rozwoju podmiotom powiązanymn jest naszym celem. Know how zdobywane przez lata to wartościowa wiedza dla każdego przedsiębiorcy.`,
    },
    {
      header: "Sieć kontaktów",
      text: `
    Networking to sztuka nawiązywania kontaktów. Byliśmy organizatorem spotkań dla biznesu oraz uczęstniczyliśmy w różnego rodzaju networkingach. Łączenie kontaktów to ważny element każdego biznesu.                      
   `,
    },
    {
      header: "BPO",
      text: `
      To najszybciej rozwijający się sektor usług dla biznesu. Nowoczesne usługi dla biznesu to rozwiązanie dla szybko rozwijających się firm chcących skupić się na najważniejszych dla firmy procesach. RDB Group oferuje outsourcing wszystkich procesów marketingowych. `,
    },
    {
      header: "Szkolenia",
      text: `
      Europejskie Centrum Edukacji i Biznesu powstało w 2019 roku i uzyskało wpis do RPS i RIS. Głównym celem ośrodka jest pozyskiwanie potrzebnych szkoleniowców, praktyków i organizacja szkoleń realizowanych dla sektora B2B.`,
    },
  ],
  brands: [
    {
      external: "https://eceib.pl",
      header: "ECEIB",
      subheader: "Europejskie Centrum Edukacji i Biznesu",
      text: `
      Europejskie Centrum Edukacji i Biznesu to ośrodek szkoleniowy stworzony na potrzeby pozyskiwania umiejętności i kształtowania specjalizacji wpisujących się w koncepcję zawodu przyszłości.
   `,
    },
    {
      external: "https://appclever.pl",
      header: "AppClever",
      subheader: "Producent Rozwiązań digitalowych",
      text: `
      AppClever jest firmą zajmującą się wdrażaniem produktów digitalowych dla klientów biznesowych.
`,
    },
    {
      external: "https://zagrawerowani.pl",
      header: "Zagrawerowani",
      subheader: "Pracowania grawerska",
      text: `
      Pracownia Grawerska oferująca ekspresowe usługi dla klientów indywidualnych. Grawerują szkło, drewno, metale, skórę i podejmują się wykonania grawerów na najdziwniejszych przedmiotach.
`,
    },
    // {
    //   url: '',
    //   header: 'Zahaftowani',
    //   subheader: 'Znakowanie Produktów Tekstylnych',
    //   text: `
    //     Hafciarnia nastawiona na najlepszą obsługe klienta i wysoką jakość każdego haftu. W tym miejscu nie ma minimalnych nakładów, a jedynym ograniczeniem jest Twoja wizja.
    //           `
    // },
    // {
    //   url: '',
    //   header: 'WearID',
    //   subheader: 'Znakowanie Produktów Tekstylnych',
    //   text: `
    //     Studio Haftu umożliwiające projektantom i markom odzieżowym rozpoczęcie serii od niskonakładowych projektów. Studio WearID projektuje haft komputerowy w oparciu o rysunki, grafiki i wektory.
    //           `
    // },
    {
      external: "https://parkergdansk.pl",
      header: "Parker Gdańsk",
      subheader: "Sklep Firmowy Parker",
      text: `
      Parker Gdańsk to odpowiedź na rosnące zainteresowanie marki. Długopisy i pióra Parkera są od lat jednym z najczęściej wybieranych marek na świecie. Sklep oferuje  innowacyjny system obsługi przygotowany specjalnie z myślą o najbardziej wymagających klientach.
`,
    },
    {
      external: "https://www.pieczatkinajutro.pl",
      header: "Pieczątki na Jutro",
      subheader: "Pieczątki firmowe",
      text: `
      Sklep stacjonarny i internetowy stworzony z myślą o osobach i firmach poszukujących pieczęci. Najwyższa jakość wykonania i możliwość zamówień ekspresowych.
`,
    },
    {
      external: "https://parkergdansk.pl",
      header: "Waterman Gdańsk",
      subheader: "Sklep Firmowy Waterman",
      text: `
      Sklep oferujący wysokiej jakości pióra i długopisy oraz innowacyjny system obsługi. Przygotowany specjalnie z myślą o najbardziej wymagających klientach.
`,
    },
  ],
}

export default {
  reklama,
  doradztwo,
  biznes,
}
