import React from 'react'

function a(el) {
  return el
    .split(' ')
    .map((item, id) =>
      item === '<br/>' ? <br key={id} /> : <>{item} </>
    )
}

export default function stringEnter(el) {
  if (typeof el === 'string') {
    return <p>{a(el)}</p>
  }

  if (typeof el === 'object') {
    return el.map(el => <p>{a(el)}</p>)
  }
}